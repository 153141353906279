//
// plugins-rtl.scss
//

// rdw-editor

.rdw-dropdown-carettoclose, .rdw-dropdown-carettoopen{
    right: auto;
    left: 10%;
}

// form wizard

.wizard {
    .steps {
        .number{
            margin-right: 0;
            margin-left: .5rem;
        }
    }
}
